const getCustomOverlay = (naver) => {
  return class CustomOverlay extends naver.maps.OverlayView {
    constructor(options) {
      super();
      this._element = options?.element;
      this.anchor = options?.anchor || { x: 0, y: 0 };
      this.size = options?.size;
      this.zIndex = options?.zIndex;

      if (options?.position) {
        this.setPosition(options.position);
      }

      this.setMap(options?.map || null);
    }

    setPosition = (position) => {
      this._position = position;
      this.draw();
    };

    getPosition = () => {
      return this._position;
    };

    setZIndex = (zIndex) => {
      if (zIndex !== this.zIndex) {
        this.zIndex = zIndex;
        this.draw();
      }
    };

    setAnchor = (anchor) => {
      if (anchor && !!anchor.x && !!anchor.y && (anchor.x !== this.anchor.x || anchor.y !== this.anchor.y)) {
        this.anchor.x = anchor.x;
        this.anchor.y = anchor.y;
        this.draw();
      }
    };

    onAdd = () => {
      const overlayLayer = this.getPanes().overlayImage;
      overlayLayer.appendChild(this._element);
    };

    draw = () => {
      if (!this.getMap()) return;

      const projection = this.getProjection();
      const position = this.getPosition();
      const pixelPosition = projection.fromCoordToOffset(position);

      this._element.style.position = 'absolute';
      this._element.style.left = pixelPosition.x + this.anchor.x + 'px';
      this._element.style.top = pixelPosition.y + this.anchor.y + 'px';

      if (this.size) {
        this._element.style.width = this.size.width + 'px';
        this._element.style.height = this.size.height + 'px';
      }
      if (this.zIndex) {
        this._element.style.zIndex = this.zIndex;
      }
    };

    onRemove = () => {
      // it's because IE does not support remove method
      if (this._element.remove) {
        this._element.remove();
      } else {
        this._element.parentNode.removeChild(this._element);
      }
    };
  };
};

export default getCustomOverlay;
