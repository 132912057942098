import { withPrefix } from 'gatsby';
import styled from 'styled-components';

import media from '~/utils/media';

export const Map = styled.div`
  width: 100%;

  ${media.desk1600`
    height: 602px;
    margin-bottom: 80px;
  `}

  ${media.desk1200`
    height: 497px;
    margin-bottom: 56px;
  `}

  ${media.tabletB`
    height: 602px;
    margin-bottom: 60px;
  `}

  ${media.tablet`
    height: 602px;
    margin-bottom: 60px;
  `}

  ${media.mobile`
    height: 300px;
    margin-bottom: 35px;
  `}

  ${media.mobileS`
    height: 250px;
    margin-bottom: 35px;
  `}
`;

export const Marker = styled.div`
  width: 30px;
  height: 44px;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  background: url(${withPrefix('/images/home/marker.svg')}) center center / cover no-repeat;
`;
