import React from 'react';

import Layout from '~/components/Layout';
import Seo from '~/components/seo';

import Banner from '~/components/Home/Banner';
import WhoWeAre from '~/components/Home/WhoWeAre';
import History from '~/components/Home/History';
import Download from '~/components/Home/Download';
import Contact from '~/components/Home/Contact';

export default function HomePage() {
  return (
    <Layout>
      <div className="Wrap">
        <Banner />
        <WhoWeAre />
      </div>
      <History />
      <div className="Wrap">
        <Download />
        <Contact />
      </div>
    </Layout>
  );
}

export function Head() {
  return (
    <Seo title="스테이션3 소개" />
  );
}
