import React, { useRef, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import getCustomOverlay from './custom-overlay';

import { Map, Marker } from './styled';

const LatLng = {
  lat: 37.494335,
  lng: 127.014547,
}

function MapComponent() {
  const _map = useRef();
  const _mapInstance = useRef();

  const [dom, setDom] = useState(false);

  useEffect(() => {
    const naver = typeof window !== 'undefined' ? window.naver : null;

    if (naver) {
      _mapInstance.current = new naver.maps.Map(_map.current, {
        useStyleMap: true,
        center: LatLng,
        zoom: 18,
        mapTypes: new naver.maps.MapTypeRegistry({
          normal: naver.maps.NaverStyleMapTypeOption.getNormalMap({
            maxZoom: 20,
            minZoom: 16,
            overlayType: 'bg.ol.ts.lko',
          }),
        }),
      });

      const overlayElem = document.createElement('div');
      const CustomOverlay = getCustomOverlay(naver);

      new CustomOverlay({
        element: overlayElem,
        position: { y: LatLng.lat, x: LatLng.lng },
        map: _mapInstance.current,
      });

      setDom(overlayElem);
    }

    return () => {
      _mapInstance.current?.destroy();
    };
  }, []);

  return (
    <Map ref={_map}>
      {dom && createPortal(
        <Marker />,
        dom
      )}
    </Map>
  );
}

export default MapComponent;
